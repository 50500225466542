import React, { useContext, useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';
import Tilt from 'react-tilt';
import { Container, Row, Col, Carousel, CarouselItem } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import Title from '../Title/Title';
import ProjectImg from '../Image/ProjectImg';

const Projects = () => {
  const { projects } = useContext(PortfolioContext);

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="projects">
      <Container>
        <div className="project-wrapper">
          <Title title="Galeria" />
          {projects.map((project) => {
            const { title, info, info2, url, repo, desc, id, photos } = project;

            return (
              <Row key={id}>
                <Col lg={4} sm={12}>
                  <Fade
                    left={isDesktop}
                    bottom={isMobile}
                    duration={500}
                    delay={100}
                    distance="30px"
                  >
                    <div className="project-wrapper__text">
                      <h3 className="project-wrapper__text-title">{title || 'Project Title'}</h3>
                      <div>
                        <ul>
                          {desc.map((paragraph) => (
                            <p>
                              <li>{paragraph}</li>
                            </p>
                          ))}
                        </ul>
                        <p className="mb-4">{info2 || ''}</p>
                      </div>

                      {repo && (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="cta-btn text-color-main"
                          href={repo}
                        >
                          Source Code
                        </a>
                      )}
                    </div>
                  </Fade>
                </Col>
                <Col lg={8} sm={12}>
                  <Fade
                    right={isDesktop}
                    bottom={isMobile}
                    duration={500}
                    delay={100}
                    distance="30px"
                  >
                    <div className="project-wrapper__image">
                      <Carousel interval={null}>
                        {photos &&
                          photos.map((photo) => (
                            <CarouselItem>
                              <Tilt
                                options={{
                                  reverse: false,
                                  max: 8,
                                  perspective: 1000,
                                  scale: 1,
                                  speed: 300,
                                  transition: true,
                                  axis: null,
                                  reset: true,
                                  easing: 'cubic-bezier(.03,.98,.52,.99)',
                                }}
                              >
                                <div data-tilt className="thumbnail rounded">
                                  <ProjectImg alt={title} filename={photo} />
                                </div>
                              </Tilt>
                            </CarouselItem>
                          ))}
                      </Carousel>
                    </div>
                  </Fade>
                </Col>
              </Row>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

export default Projects;
