import React, { useContext, useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import Title from '../Title/Title';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';

const Contact = () => {
  const { contact } = useContext(PortfolioContext);
  const { cta, btn, email } = contact;
  const position = [41.54941, -8.41149];

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="contact">
      <Container>
        <Title title="Contactos" />
        <Row>
          <Col className="contact-info-col" lg={6} sm={12}>
            <Fade right={isDesktop} bottom={isMobile} duration={500} delay={100} distance="30px">
              <div className="contact-info-text">
                <h3 className="contact-info-text-title">Morada</h3>
                <p>
                  Rua Bernardo Sequeira,
                  <br />
                  N.º 231, 1º Andar - Sala 7<br />
                  4715-010 Braga
                </p>
              </div>
              <div className="contact-info-text">
                <h3 className="contact-info-text-title">Telefone</h3>
                <p>
                  (+351) 253 260 647 (Chamada para a rede fixa nacional)
                  <br />
                  (+351) 914 915 922 (Chamada para a rede móvel nacional)
                </p>
              </div>
              <div className="contact-info-text">
                <h3 className="contact-info-text-title">E-mail</h3>
                <p>paulo@pauloalves.pt</p>
              </div>
            </Fade>
          </Col>
          <Col className="contact-map-col" lg={6} sm={12}>
            <Fade right={isDesktop} bottom={isMobile} duration={500} delay={100} distance="30px">
              {typeof window !== 'undefined' && (
                <MapContainer className="contact-map-col-map" center={position} zoom={15}>
                  <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <Marker position={position} />
                </MapContainer>
              )}
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Contact;
