import React, { useContext, useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-scroll';
import PortfolioContext from '../../context/context';
import HeroImg from '../Image/HeroImg';

const Header = () => {
  const { hero } = useContext(PortfolioContext);
  const { name, subtitle, cta, img } = hero;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="hero" className="jumbotron">
      <Container className="top-bar d-none d-lg-block">
        <Row className="top-bar d-none d-lg-flex">
          <Col>

              <div className="hero-img">
                <HeroImg alt="company logo" filename={img} />
              </div>
          </Col>
          <Col>
            <div className="hero-body">
                <h1 className="hero-title">{subtitle || ''}</h1>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="d-lg-none d-sm-flex mobile-container">
        <Row className="mobile-container">
          <div className="hero-img">
            <HeroImg alt="company logo" filename={img} />
          </div>
        </Row>
        <Row className="mobile-container">
            <p className="hero-cta">
              <span className="cta-btn cta-btn--resume">
                <Link to="about" smooth duration={1000}>
                  {cta || ''}
                </Link>
              </span>
            </p>
        </Row>
      </Container>
      <Container className="nav-bar d-none d-lg-block">
        <Row className="nav-bar d-none d-lg-flex">
          <Col>
              <span className="cta-btn cta-btn--resume">
                <Link to="about" smooth duration={1000}>
                  A Empresa
                </Link>
              </span>
          </Col>
          <Col>
              <span className="cta-btn cta-btn--resume no-opacity">
                <Link to="features" smooth duration={1000}>
                  Âmbito de Atuação
                </Link>
              </span>
          </Col>
          <Col>
              <span className="cta-btn cta-btn--resume">
                <Link to="projects" smooth duration={1000}>
                  Galeria
                </Link>
              </span>
          </Col>
          <Col>
              <span className="cta-btn cta-btn--resume">
                <Link to="contact" smooth duration={1000}>
                  Contactos
                </Link>
              </span>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Header;
