import React, { useContext, useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';
import Tilt from 'react-tilt';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import Title from '../Title/Title';
import ProjectImg from '../Image/ProjectImg';

const Features = () => {
  const { features } = useContext(PortfolioContext);

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isCardOpen, setCardOpen] = useState([false, false, false, false]);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  function toggleCard(index) {
    let array = [];
    for (let i = 0; i < isCardOpen.length; i++) {
      if (i !== index) {
        array.push(isCardOpen[i]);
      } else {
        array.push(!isCardOpen[i]);
      }
    }
    setCardOpen(array);
  }

  return (
    <section id="features">
      <Container>
        <div className="feature-wrapper">
          <Title title="Âmbito de Atuação" />
          <Row>
            {features.map((feature, index) => {
              const { title, info, url, img, id } = feature;

              return (
                <Col lg={6} sm={12}>
                  <Fade
                    right={isDesktop}
                    bottom={isMobile}
                    duration={500}
                    delay={100}
                    distance="30px"
                  >
                    <Card>
                      <ProjectImg filename={img} />
                      <Card.Body>
                        <Card.Title>
                          <h3 className="title">{title || 'Project Title'}</h3>
                        </Card.Title>
                        {isCardOpen[index] ? (
                          <Card.Text>
                            <ul>
                              {info.map((paragraph) => (
                                <p>
                                  <li>{paragraph}</li>
                                </p>
                              ))}
                            </ul>
                          </Card.Text>
                        ) : (
                          <div />
                        )}
                        <i
                          className={`fa fa-${isCardOpen[index] ? 'chevron-up' : 'chevron-down'}`}
                          onClick={() => toggleCard(index)}
                        />
                      </Card.Body>
                    </Card>
                  </Fade>
                </Col>
              );
            })}
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default Features;
